import React from 'react';
import styled from '@emotion/styled';

const StylesProvider = styled.article`
  width: 650px;
  margin: 0 auto;
  padding: 30px;

  font-family: Poppins, Helvetica, Arial, sans-serif;

  --color-primary: #db6d1f;

  h1 {
    color: var(--color-primary);
    font-size: 3.5em;
    margin: 0.5em 0 0;
  }

  h2 {
    color: var(--color-primary);

    b {
      color: slategray;
    }
  }

  a {
    color: var(--color-primary);
    text-decoration: none;

    &:hover {
      color: #333;
      text-decoration: none;
    }
  }
`;

export const Maintenance = () => (
  <StylesProvider>
    <title>Site Maintenance</title>
    <img src="https://assets.virtuallucyweb.co.uk/img/VirtualLucy.png" alt="Virtual Lucy" width={184} height={184} />
    <h1>We&rsquo;ll be back soon...</h1>
    <div>
      <p>
        Sorry for the inconvenience but we&rsquo;re performing some planned maintenance at the moment. If you need to
        you can always <a href="mailto:support@virtuallucy.co.uk">contact us</a>, otherwise we&rsquo;ll be back online
        shortly.
      </p>
      <h2>
        Virtual <b>Lucy&trade;</b>
      </h2>
    </div>
  </StylesProvider>
);
