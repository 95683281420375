const ConfigLink = {
  AccountLogin: '/account/login',
  BVT: '/hbsbvt',
  SetYourPassword: '/account/set-your-password',
  ResetYourPassword: '/account/reset-your-password',
  wrongFlow: '/account/wrong-flow',
  Register: '/account/register',
  ForgetPassword: '/account/forget-password',
  ConfirmEmail: '/account/confirm-email/:token', // without auth
  Dashboard: '/admin/dashboard',
  Appointments: '/admin/appointments',
  AdminHBSBookAppointmentStart: '/admin/BookAppointmentStart',
  AdminHBSBookAppointmentSearch: '/admin/BookAppointmentSearch-new',
  AdminHBSBookAppointmentExist: '/admin/BookAppointmentSearch-exist',
  AdminHBSScheduleAppointment: '/admin/ScheduleAppointment',
  AdminHBSConfirmAppointment: '/admin/ConfirmAppointment',
  AdminHBSBookF2FAppointment: '/admin/BookF2FAppointment',
  AdminHBSTriageQuestions: '/admin/TriageQuestions',
  AdminPIFU: '/admin/pifus',
  Clinicians: '/admin/clinicians',
  DetailsClinicians: '/admin/clinicians/details',
  Tasks: '/admin/tasks',
  Reports: '/admin/reports',
  Report: '/admin/report',
  Requests: '/admin/requests',
  ReferrerReports: '/referrer-admin/reports',
  ReferrerReport: '/referrer-admin/report',
  ReportingReferrerReports: '/referrer/reports',
  ReportingReferrerReport: '/referrer/report',
  //case
  Cases: '/admin/cases',
  CreateNewCase: '/admin/create-case',
  Settings: '/admin/settings',
  MyProfile: '/admin/my-profile',
  MyProfileReferrerAdmin: '/referrer-admin/my-profile',
  MyProfileReferrer: '/referrer/my-profile',
  Company: '/admin/company',
  Consultants: '/admin/consultants',
  ViewTimeLine: '/admin/ViewTimeLine',
  ViewCompany: '/admin/ViewCompany',
  ReferrerDashboard: '/referrer/dashboard',
  ReferrerAppointments: '/referrer/ReferrerAppointments',
  ReferrerTasks: '/referrer/ReferrerTasks',
  ReferrerViewTimeLine: '/referrer/ReferrerViewTimeLine',
  ReferrerCases: '/referrer/cases',
  ReferrerSupport: '/referrer/support',
  BookAppointmentStart: '/referrer/BookAppointmentStart',
  BookAppointmentSearch: '/referrer/BookAppointmentSearch-new',
  BookAppointmentExist: '/referrer/BookAppointmentSearch-exist',
  ReferrerSearch: '/referrer/search',
  ReferrerScheduleAppointment: '/referrer/ScheduleAppointment',
  ReferrerConfirmAppointment: '/referrer/ConfirmAppointment',
  ReferrerCreateNewCase: '/referrer/create-case',
  ReferrerAdminDashboard: '/referrer-admin/dashboard',
  ReferrerAdminCases: '/referrer-admin/cases',
  ReferrerAdminSupport: '/referrer-admin/support',
  ReferrerAdminAppointments: '/referrer-admin/ReferrerAppointments',
  ReferrerAdminBookAppointmentStart: '/referrer-admin/BookAppointmentStart',
  ReferrerAdminBookAppointmentSearch: '/referrer-admin/BookAppointmentSearch-new',
  ReferrerAdminBookAppointmentExist: '/referrer-admin/BookAppointmentExist-exist',
  ReferrerAdminScheduleAppointment: '/referrer-admin/ScheduleAppointment',
  ReferrerAdminConfirmAppointment: '/referrer-admin/ConfirmAppointment',
  ReferrerAdminViewTimeLine: '/referrer-admin/ReferrerViewTimeLine',
  ReferrerAdminTasks: '/referrer-admin/ReferrerTasks',
  ReferrerAdminReferrers: '/referrer-admin/Referrers',
  ReferrerAdminSearch: '/referrer-admin/search',
  ReferrerAdminCreateNewCase: '/referrer-admin/create-case',
  Patients: '/admin/patients',
  PatientDashboard: '/patient/dashboard',
  PatientAppointments: '/patient/appointments',
  PatientAppointmentSession: '/patient/appointments-session',
  PatientNotifications: '/patient/notifications',
  PatientSurveys: '/patient/surveys',
  PatientRescheduleAppointment: '/patient/rescheduleappointment',
  PatientViewTimeline: '/patient/viewtimeline',
  PatientDetails: '/referrer/patient-details',
  AdminPatientDetails: '/admin/patient-details',
  ReferrerAdminPatientDetails: '/referrer-admin/patient-details',
  PromsDashboard: '/clinician/proms-dashboard',
  Surveys: '/clinician/surveys',
  ClinicianAppointments: '/clinician/appointments',
  Assessment: '/admin/assessment',
  AssessmentView: '/admin/assessment-view',
  Email: '/admin/email',
  EmailView: '/admin/email-view',
  // External (no auth)
  ImagingRequestStatusForm: '/imaging-req-form'
};

export default ConfigLink;
